var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading,"text":""},on:{"click":_vm.getCalls}},[_c('v-icon',[_vm._v("mdi-sync")]),_vm._v(" "+_vm._s(_vm.$t("labelUpdate"))+" ")],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.heads,"items":_vm.missedcall,"footer-props":_vm.footerProps,"options":_vm.options,"server-items-length":_vm.totalmiss,"item-key":"index","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"phone-number"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.statusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.itemStatus(item))+" ")]),_vm._v(" "+_vm._s(_vm.getFrom(item))+" ")],1)]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"phone-number"},[(!_vm.admin)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.statusColor(item.status)}},[_vm._v(_vm._s(_vm.itemStatus(item)))]):_vm._e(),_vm._v(" "+_vm._s(_vm.getTo(item))+" ")],1)]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.prettyTime(item.duration)))])]}},{key:"item.origin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm._origin(item)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm._name(item)))])]}},{key:"item.createAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.formatDate(item.createAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isSuper)?[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.setRecordToRemove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]:[_c('btn-make-call',{attrs:{"fromcall":true,"numberPatient":_vm.numberShow(item)}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"info"},on:{"click":function($event){return _vm.setContact(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-message-text-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Send SMS")])])]]}}],null,true)},[_vm._v(" "+_vm._s(/* From agent */)+" "),_vm._v(" "+_vm._s(/* To agent */)+" "),_vm._v(" "+_vm._s(/* Duration */)+" "),_vm._v(" "+_vm._s(/* Origin */)+" "),_vm._v(" "+_vm._s(/* Name */)+" "),_vm._v(" "+_vm._s(/* Date */)+" "),_vm._v(" "+_vm._s(/* Remove record */)+" "),_c('not-records',{attrs:{"slot":"no-data"},slot:"no-data"})],1),_vm._v(" "+_vm._s(/* Confirm remove */)+" "),_c('v-dialog',{attrs:{"max-width":"280","content-class":"rounded-xl","persistent":_vm.loading},model:{value:(_vm.dialogConfirmRemove),callback:function ($$v) {_vm.dialogConfirmRemove=$$v},expression:"dialogConfirmRemove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 font-weight-regular rounded-xl"},[_vm._v(" "+_vm._s(_vm.$t("dialogTitle"))+" ")]),_c('v-layout',{staticClass:"pa-4",attrs:{"column":""}},[_c('div',{staticClass:"text-body-1 text-truncate d-flex mb-2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green"}},[_vm._v("mdi-phone-outgoing")]),_c('span',{staticClass:"dosis"},[_vm._v(_vm._s(_vm.currentRecord.from))])],1),_c('div',{staticClass:"text-body-1 text-truncate d-flex"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green"}},[_vm._v("mdi-phone-incoming")]),_c('span',{staticClass:"dosis"},[_vm._v(_vm._s(_vm.cleanPhone(_vm.currentRecord.to)))])],1)]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loading,"text":""},on:{"click":function($event){_vm.dialogConfirmRemove = false}}},[_vm._v(" "+_vm._s(_vm.$t("labelCancel"))+" ")]),_c('v-btn',{attrs:{"color":"red","loading":_vm.loading,"text":""},on:{"click":_vm.remove}},[_vm._v(" "+_vm._s(_vm.$t("labelRemove"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }