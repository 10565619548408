












































































































































/**
 * Show missed calls
 */
import Vue from "vue";
import { CallList } from "@/mixins";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import NotRecords from "@/components/NotRecords.vue";
import RecordedCall from "@/models/RecordedCall";
import BtnMakeCall from "./buttons/BtnMakeCall.vue";
// import TableToolbar from "./TableToolbar.vue";

export default Vue.extend({
  // components: { TableToolbar },
  name: "missed-calls",
  mixins: [CallList],
  components: {
    NotRecords,
    BtnMakeCall,
  },
  data() {
    return {
      footerProps: { "items-per-page-options": [15, 50, 100] },
      options: {},

      reqbody: {
        range: {
          limit: 15,
          offset: 0,
        },
        missed: true,
      },
      headers: [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("crmCallCenter.from"),
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("crmCallCenter.to"),
          align: "start",
          sortable: false,
          value: "to",
        },
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        (this as any).getCalls();
      }
    },
  },
  destroyed() {
    (this as any).mutRequest(null);
    (this as any).mutFilter(false);
  },
  computed: {
    ...mapGetters(["isSuper", "isAdmin", "getPhone", "getUser"]),
    ...mapState(["totalitems"]),
    ...mapState("crmCallCenterModule", [
      "missList",
      "totalmiss",
      "requestC",
      "filter",
    ]),
    admin() {
      if (this.isSuper || this.isAdmin) {
        return true;
      }
      return false;
    },
    missedcall() {
      if (this.admin) {
        return this.missList;
      }
      const misscall = this.missList.filter(
        (mcall: RecordedCall) =>
          mcall.to == this.getPhone || mcall.to == `client:${this.getUser}`
      );
      return misscall;
    },

    heads() {
      const temp = [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Number",
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
      ];
      return this.admin ? (this as any).headers : temp;
    },
  },
  mounted() {
    (this as any).mutView("miss");
    (this as any).getCalls();
  },
  methods: {
    ...mapActions("crmCallCenterModule", ["actGetCallsMis"]),
    ...mapMutations("crmCallCenterModule", [
      "mutRequest",
      "mutFilter",
      "mutView",
    ]),
    ...mapMutations("crmSMSModule", ["mutSetContact"]),
    ...mapMutations(["mutReqRange"]),
    itemStatus(call: RecordedCall) {
      if (call.status == "completed") {
        return "mdi-phone-check";
      } else if (call.status == "queued") {
        return "mdi-phone-settings";
      } else if (call.status == "ringing") {
        return "mdi-phone-in-talk";
      } else if (call.status == "canceled") {
        return "mdi-phone-missed";
      } else {
        if (
          call.from == this.getPhone ||
          call.from == `client:${this.getUser}`
        ) {
          return "mdi-phone-forward-outline";
        }
        return "mdi-phone-missed";
      }
    },
    numberShow(call: RecordedCall) {
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return call.to;
      } else {
        return call.from;
      }
    },

    _origin(item: RecordedCall) {
      if (item.patient_name != null) {
        return "PATIENT";
      }
      if (item.l_phone != null) {
        return "LEAD";
      }
      if (item.employee_fullname != null) {
        return "EMPLOYEE";
      }
      return "-";
    },

    getFrom(call: RecordedCall) {
      if (call.from == null || call.from == undefined) {
        return "-";
      }
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return (this as any).cleanPhone(call.from, call);
      }
    },
    getTo(call: RecordedCall) {
      if (call.to == null || call.to == undefined) {
        return "-";
      }
      if (call.to == this.getPhone || call.to == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return (this as any).cleanPhone(call.to, call);
      }
    },
    _name(item: RecordedCall) {
      if (item.patient_name != null) {
        return item.patient_name;
      }
      if (item.l_name != null) {
        return item.l_name;
      }

      if (item.employee_fullname != null) {
        return item.employee_fullname;
      }
      return "-";
    },

    setContact(item: RecordedCall) {
      const phone = (this as any).numberShow(item);
      const contact = { number: phone, name: phone };
      (this as any).mutSetContact(contact);
      this.$router.push("/chat");
    },

    getCalls() {
      const { page, itemsPerPage }: any = (this as any).options;
      const itmper = itemsPerPage;

      let body = {
        range: { limit: itmper, offset: (page - 1) * itmper },
        missed: (this as any).reqbody.missed,
      };

      if (this.filter) {
        body = this.requestC;
      }

      (this as any).mutRequest(body);
      (this as any).actGetCallsMis(body);
    },
  },
});
